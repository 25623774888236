<template>
  <form-view v-loading="loading" type="info" noEdit>
    <div class="section-title-row">
      <div class="title">巡查基本信息</div>
      <div class="line"></div>
    </div>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-form-item label="工地名称" prop="siteName">
          <el-input disabled v-model="dataForm.siteName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="施工部位" prop="constructionPart">
          <el-input disabled v-model="dataForm.constructionPart"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-form-item label="工地地址" prop="siteAddress">
          <el-input disabled v-model="dataForm.siteAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="施工车辆" prop="number">
          <el-input disabled v-model="dataForm.number"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="25">
      <el-col :span="4">
        <el-form-item label="车长" prop="carNickName">
          <el-input disabled v-model="dataForm.carNickName"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="4">
        <el-form-item label="泵别" prop="emissionStandard">
          <el-input disabled v-model="dataForm.carSize"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="巡查类型" prop="patrolType">
          <!-- <el-input disabled v-model="dataForm.patrolType"></el-input> -->
          <el-select disabled v-model="dataForm.patrolType">
            <el-option
              v-for="item in patrolTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="巡查状态" prop="status">
          <!-- <el-input disabled v-model="dataForm.patrolType"></el-input> -->
          <el-select disabled v-model="dataForm.status">
            <el-option
              v-for="item in patrolStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="5">
        <el-form-item label="巡查时间" prop="createTime">
          <el-input disabled v-model="dataForm.createTime"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-form-item label="备注" prop="remarks">
          <el-input disabled v-model="dataForm.remarks"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div v-if="permissions.length > 0">
      <el-row>准泵令图片</el-row>
      <div style="border: solid 1px grey; margin-top: 10px;" v-for="(item, index) in permissions" :key="index">
        <el-row>申请时间: {{item.createTime}}</el-row>
        <el-row>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageBracketBeforeLeft"
                :preview-src-list="item.imageBracketBeforeLeftList"
                fit="contain">
              </el-image>
              <div class="title">左前支撑腿</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageBracketAfterLeft"
                :preview-src-list="item.imageBracketAfterLeftList"
                fit="contain">
              </el-image>
              <div class="title">左后支撑腿</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageBracketBeforeRight"
                :preview-src-list="item.imageBracketBeforeRightList"
                fit="contain">
              </el-image>
              <div class="title">右前支撑腿</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageBracketAfterRight"
                :preview-src-list="item.imageBracketAfterRightList"
                fit="contain">
              </el-image>
              <div class="title">右后支撑腿</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageWholeCar"
                :preview-src-list="item.imageWholeCarList"
                fit="contain">
              </el-image>
              <div class="title">整车</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageConfirm"
                :preview-src-list="item.imageConfirmList"
                fit="contain">
              </el-image>
              <div class="title">就位确认单</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageSingleSide"
                :preview-src-list="item.imageSingleSideList"
                fit="contain">
              </el-image>
              <div class="title">单边支撑</div>
            </div>
          </el-col>
          <el-col :span="3">
            <div>
              <el-image
                style="width: 150px; height: 150px"
                :src="item.imageHazardSource"
                :preview-src-list="item.imageHazardSourceList"
                fit="contain">
              </el-image>
              <div class="title">危险源施工方案</div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <!-- <div style="border: 1px solid grey; margin-top: 10px;">
      <el-row>摄像头截取图片</el-row>
      <el-row>
        <el-col :span="4">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items: center;">
            <el-image
              style="width: 150px; height: 150px"
              :src="dataForm.imageBracketBeforeLeft"
              :preview-src-list="dataForm.imageBracketBeforeLeftList"
              fit="contain">
            </el-image>
            <div>左前支撑腿</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items: center;">
            <el-image
              style="width: 150px; height: 150px"
              :src="dataForm.imageBracketAfterLeft"
              :preview-src-list="dataForm.imageBracketAfterLeftList"
              fit="contain">
            </el-image>
            <div class="title">左后支撑腿</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items: center;">
            <el-image
              style="width: 150px; height: 150px"
              :src="dataForm.imageBracketBeforeRight"
              :preview-src-list="dataForm.imageBracketBeforeRightList"
              fit="contain">
            </el-image>
            <div class="title">右前支撑腿</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items: center;">
            <el-image
              style="width: 150px; height: 150px"
              :src="dataForm.imageBracketAfterRight"
              :preview-src-list="dataForm.imageBracketAfterRightList"
              fit="contain">
            </el-image>
            <div class="title">右后支撑腿</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items: center;">
            <el-image
              style="width: 150px; height: 150px"
              :src="dataForm.imageBracketTurret"
              :preview-src-list="dataForm.imageBracketTurretList"
              fit="contain">
            </el-image>
            <div class="title">转塔</div>
          </div>
        </el-col>
        <el-col :span="4">
          <div style="display:flex;flex-direction:column;justify-content:center;align-items: center;">
            <el-image
              style="width: 150px; height: 150px"
              :src="dataForm.imageBracketHose"
              :preview-src-list="dataForm.imageBracketHoseList"
              fit="contain">
            </el-image>
            <div class="title">末端软管</div>
          </div>
        </el-col>
      </el-row>
    </div> -->

    <image-uploader
      title="巡查现场图"
      :images="patrolImageList || []"
      show-create-date
      disabled
    ></image-uploader>
    <el-table
      :data="tableList"
      stripe
      size="mini"
      :border="true"
      :max-height="500"
      width="200px"
    >
      <el-table-column
        header-align="center"
        align="center"
        prop="dictName"
        label="检查项目"
        width="650">
      </el-table-column>

      <el-table-column
        header-align="center"
        align="center"
        prop="patrolStatus"
        label="通过">
        <template slot-scope="scope">
          <div class="icon-warp">
            <i v-if="scope.row.patrolStatus==1" class="el-icon-success"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="不通过">
        <template slot-scope="scope">
          <div class="icon-warp">
            <i v-if="scope.row.patrolStatus==2" class="el-icon-success"></i>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="不需要">
        <template slot-scope="scope">
          <div class="icon-warp">
            <i v-if="scope.row.patrolStatus==3" class="el-icon-success"></i>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </form-view>
</template>

<script>
import FormView from '@/components/templates/form-view'
import ImageUploader from '@/components/image-uploader'
import { staticUrl } from '@/config'

export default {
  name: 'dispatch-patrol-info',

  components: { FormView, ImageUploader },

  data () {
    return {
      loading: false,
      dataForm: {},
      patrolImageList: [],
      tableList: [],
      permissions: [],
      id: null,
      patrolTypeList: [{
        label: '人工巡查',
        value: 1
      }, {
        label: '线上巡查',
        value: 2
      }],
      patrolStatusList: [{
        label: '待巡查',
        value: 1
      }, {
        label: '巡查通过',
        value: 2
      }, {
        label: '巡查发现问题',
        value: 3
      }, {
        label: '问题整改完成',
        value: 4
      }, {
        label: '问题确认完成',
        value: 5
      }]
    }
  },

  created () {
    this.init()
  },

  methods: {
    async init () {
      const id = this.$route.query.id
      if (id) {
        this.loading = false
        const data = await this.$http({
          url: this.$http.adornUrl('/dispatch/taskPatrol/getById'),
          method: 'post',
          data: id
        })
        if (data && data.code === 200) {
          this.dataForm = data.datas
          this.tableList = data.datas.contentList
          this.permissions = data.datas.permissons

          this.dataForm.imageBracketAfterLeft = this.dataForm.imageBracketAfterLeft ? staticUrl + this.dataForm.imageBracketAfterLeft : ''
          this.dataForm.imageBracketAfterLeftList = [this.dataForm.imageBracketAfterLeft]

          this.dataForm.imageBracketAfterRight = this.dataForm.imageBracketAfterRight ? staticUrl + this.dataForm.imageBracketAfterRight : ''
          this.dataForm.imageBracketAfterRightList = [this.dataForm.imageBracketAfterRight]

          this.dataForm.imageBracketBeforeLeft = this.dataForm.imageBracketBeforeLeft ? staticUrl + this.dataForm.imageBracketBeforeLeft : ''
          this.dataForm.imageBracketBeforeLeftList = [this.dataForm.imageBracketBeforeLeft]

          this.dataForm.imageBracketBeforeRight = this.dataForm.imageBracketBeforeRight ? staticUrl + this.dataForm.imageBracketBeforeRight : ''
          this.dataForm.imageBracketBeforeRightList = [this.dataForm.imageBracketBeforeRight]

          this.dataForm.imageBracketHose = this.dataForm.imageBracketHose ? staticUrl + this.dataForm.imageBracketHose : ''
          this.dataForm.imageBracketHoseList = [this.dataForm.imageBracketHose]

          this.dataForm.imageBracketTurret = this.dataForm.imageBracketTurret ? staticUrl + this.dataForm.imageBracketTurret : ''
          this.dataForm.imageBracketTurretList = [this.dataForm.imageBracketTurret]

          for (let i = 0; i < this.permissions.length; i++) {
            if (this.permissions[i].imageBracketAfterLeft !== '') {
              this.permissions[i].imageBracketAfterLeft = staticUrl + this.permissions[i].imageBracketAfterLeft
              let imageBracketAfterLeftList = []
              imageBracketAfterLeftList.push(this.permissions[i].imageBracketAfterLeft)
              this.permissions[i].imageBracketAfterLeftList = imageBracketAfterLeftList
            }

            if (this.permissions[i].imageBracketAfterRight !== '') {
              this.permissions[i].imageBracketAfterRight = staticUrl + this.permissions[i].imageBracketAfterRight
              let imageBracketAfterRightList = []
              imageBracketAfterRightList.push(this.permissions[i].imageBracketAfterRight)
              this.permissions[i].imageBracketAfterRightList = imageBracketAfterRightList
            }

            if (this.permissions[i].imageBracketBeforeLeft !== '') {
              this.permissions[i].imageBracketBeforeLeft = staticUrl + this.permissions[i].imageBracketBeforeLeft
              let imageBracketBeforeLeftList = []
              imageBracketBeforeLeftList.push(this.permissions[i].imageBracketBeforeLeft)
              this.permissions[i].imageBracketBeforeLeftList = imageBracketBeforeLeftList
            }

            if (this.permissions[i].imageBracketBeforeRight !== '') {
              this.permissions[i].imageBracketBeforeRight = staticUrl + this.permissions[i].imageBracketBeforeRight
              let imageBracketBeforeRightList = []
              imageBracketBeforeRightList.push(this.permissions[i].imageBracketBeforeRight)
              this.permissions[i].imageBracketBeforeRightList = imageBracketBeforeRightList
            }

            if (this.permissions[i].imageConfirm !== '') {
              this.permissions[i].imageConfirm = staticUrl + this.permissions[i].imageConfirm
              let imageConfirmList = []
              imageConfirmList.push(this.permissions[i].imageConfirm)
              this.permissions[i].imageConfirmList = imageConfirmList
            }
            if (this.permissions[i].imageHazardSource !== '') {
              this.permissions[i].imageHazardSource = staticUrl + this.permissions[i].imageHazardSource
              let imageHazardSourceList = []
              imageHazardSourceList.push(this.permissions[i].imageHazardSource)
              this.permissions[i].imageHazardSourceList = imageHazardSourceList
            }
            if (this.permissions[i].imageSingleSide !== '') {
              this.permissions[i].imageSingleSide = staticUrl + this.permissions[i].imageSingleSide
              let imageSingleSideList = []
              imageSingleSideList.push(this.permissions[i].imageSingleSide)
              this.permissions[i].imageSingleSideList = imageSingleSideList
            }
            if (this.permissions[i].imageWholeCar !== '') {
              this.permissions[i].imageWholeCar = staticUrl + this.permissions[i].imageWholeCar
              let imageWholeCarList = []
              imageWholeCarList.push(this.permissions[i].imageWholeCar)
              this.permissions[i].imageWholeCarList = imageWholeCarList
            }
          }
          console.log(this.permissions)
          if (this.dataForm.images != null) {
            this.patrolImageList = this.dataForm.images.split(',').map(j => ({ url: '/files' + j }))
          } else {
            this.patrolImageList = []
          }
          this.loading = false
          console.log(this.patrolImageList)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  /*.bracket-area {*/
  /*  .el-form-item__content {*/
  /*    white-space: nowrap;*/
  /*    .el-input {*/
  /*      width: 80%;*/
  /*    }*/
  /*    .el-input + label {*/
  /*      margin-left: 10px;*/
  /*    }*/
  /*  }*/
  /*}*/
  /*.flex-row {*/
  /*  margin: 20px 0;*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  .el-select,*/
  /*  .el-date-editor {*/
  /*    width: 150px;*/
  /*    flex: 0 0 auto;*/
  /*  }*/
  /*  label {*/
  /*    margin: 0 10px;*/
  /*    white-space: nowrap;*/
  /*  }*/
  /*  .el-input.remark {*/
  /*    flex: 1;*/
  /*    width: auto;*/
  /*  }*/
  /*  .icon {*/
  /*    font-size: 20px;*/
  /*    margin-left: 10px;*/
  /*    cursor: pointer;*/
  /*    color: #666;*/
  /*    &:hover {*/
  /*      color: #999;*/
  /*    }*/
  /*  }*/
  /*}*/
  /*.license-area {*/
  /*  margin-bottom: 20px;*/
  /*  display: flex;*/
  /*  justify-content: space-around;*/
  /*}*/
  .icon-warp{
    font-size: 20px;
  }
  .title {
      font-size: 14px;
      text-align: center;
  }
</style>
